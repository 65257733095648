'use client'

import { Link } from '@/navigation'
import { ArrowRight, Menu } from 'lucide-react'
import { usePathname } from 'next/navigation'
import { useState } from 'react'

const MobileNav = ({ isAuth }: { isAuth: boolean }) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const toggleOpen = () => setOpen(!isOpen)

  const pathname = usePathname()

  // useEffect(() => {
  //   if (isOpen) toggleOpen()
  // }, [pathname, isOpen])

  const closeOnCurrent = (href: string) => {
    if (pathname.indexOf('/' + href) >= 0) {
      toggleOpen()
    }
  }

  return (
    <div className="sm:hidden">
      <Menu onClick={toggleOpen} className="relative z-50 h-5 w-5 text-zinc-700" />

      {isOpen ? (
        <div className="fixed inset-0 z-0 w-full animate-in fade-in-20 slide-in-from-top-5">
          <ul className="absolute grid w-full gap-3 border-b border-zinc-200 bg-white px-10 pb-8 pt-20 shadow-xl">
            {!isAuth ? (
              <>
                <li>
                  <Link
                    onClick={() => closeOnCurrent('/sign-up')}
                    className="flex w-full items-center font-semibold text-green-600"
                    href={`/sign-up`}
                  >
                    Get started
                    <ArrowRight className="ml-1.5 h-5 w-5" />
                  </Link>
                </li>
                <li className="my-3 h-px w-full bg-gray-300" />
                <li>
                  <Link
                    onClick={() => closeOnCurrent('/sign-in')}
                    className="flex w-full items-center font-semibold"
                    href={`/sign-in`}
                  >
                    Sign in
                  </Link>
                </li>
                <li className="my-3 h-px w-full bg-gray-300" />
              </>
            ) : (
              <>
                <li>
                  <Link
                    onClick={() => closeOnCurrent('/dashboard')}
                    className="flex w-full items-center font-semibold"
                    href={`/dashboard`}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => closeOnCurrent('/courtrulings')}
                    className="flex w-full items-center font-semibold"
                    href={`/courtrulings`}
                  >
                    Court Rulings
                  </Link>
                </li>
                <li className="my-3 h-px w-full bg-gray-300" />
                <li>
                  <Link className="flex w-full items-center font-semibold" href={`/sign-out`}>
                    Sign out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

export default MobileNav
