import axios from 'axios'
import { clsx, type ClassValue } from 'clsx'
import fileDownload from 'js-file-download'
import { Metadata } from 'next'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const absoluteUrl = (path: string) => {
  if (typeof window !== 'undefined') return path
  // There was an authentication problem when using VERCEL_URL, maybe we have to use the fixed value [https://www.courtai.ch]
  if (process.env.VERCEL_URL) return `https://www.courtai.ch${path}`
  return `http://localhost:${process.env.PORT ?? 3000}${path}`
}

export const sanitizeStringUTF8 = (str: string) => {
  // return str.replace(/\uFFFD/g, '')
  return str.replace(/\0/g, '')
}

export function languageName(language: string) {
  if (language === 'DE') {
    return 'German'
  } else if (language === 'EN') {
    return 'English'
  } else if (language === 'FR') {
    return 'French'
  } else if (language === 'IT') {
    return 'Italian'
  }
}

export const handleFileDownload = (url: string, name: string) => {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, name)
    })
}

export async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export function hslColPercToHex(percent, shade = '50%', start = 0, end = 120) {
  var a = percent,
    b = (end - start) * a,
    c = b + start
  return 'hsl(' + c + ', 75%, ' + shade + ')'
}

export function splitArrayInChunks(array: any[], chunkSize: number) {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (v, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize),
  )
}

export function shuffleArray(array: any[]) {
  let currentIndex = array.length,
    randomIndex: number
  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }
  return array
}

export function constructMetadata({
  title = 'CourtAI - Improving your workflow with AI.',
  description = 'CourtAI is a software to interact with court rulings with AI.',
  image = '/thumbnail.png',
  icons = '/favicon.ico',
  noIndex = false,
}: {
  title?: string
  description?: string
  image?: string
  icons?: string
  noIndex?: boolean
} = {}): Metadata {
  return {
    title,
    description,
    openGraph: {
      title,
      description,
      images: [
        {
          url: image,
        },
      ],
    },
    twitter: {
      card: 'summary_large_image',
      title,
      description,
      images: [image],
      creator: '@husmaret',
    },
    icons,
    metadataBase: new URL('https://courtai.vercel.app'),
    ...(noIndex && {
      robots: {
        index: false,
        follow: false,
      },
    }),
  }
}
